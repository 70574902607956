// 部門控制器
import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/auth'
const modules = '/departments'
const path = base + modules

// request(REQ) Api  --------------------------------
const getDepartmentsREQ = path + '/list' // 獲取部門列表
const getDeptDetailREQ = path + '/list/detail' // 獲取部門詳細信息(包含座席狀態)
const addDeptREQ = path + '/add' // 添加部門
const editDeptREQ = path + '/update' // 更新部門
const deleteDeptREQ = path + '/del' // 刪除部門
const searchDeptREQ = path + '/list/q' // 搜尋部門

// request(REQ) Function  --------------------------------

/**
 * 獲取部門列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getDepartmentsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getDepartmentsREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取部門詳細信息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getDeptDetailFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getDeptDetailREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加部門
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const addDeptFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addDeptREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新部門
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editDeptFn = (params, formData, callback) => { // 更新部門
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editDeptREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除部門
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteDeptFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteDeptREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 搜尋部門
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const searchDeptFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(searchDeptREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}
